import {
    MagnifyingGlassIcon,
    Bars3CenterLeftIcon,
    PencilSquareIcon,
    XMarkIcon,
    PencilIcon,
} from "@heroicons/react/24/outline";
import React, { useState, Fragment, useEffect, useRef } from "react";
import Topics from "../Topics";
import Feeds from "../Feeds";
import styles from "./styles.module.css";
import { ActivityChannelProps } from "../../interfaces/componentsInterface/activityChannel";
import { Dialog, Transition } from "@headlessui/react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import Button from "../Button";
import SocketService from "../../services/socket";
import { socketConnectiontype, socketPathName } from "../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import {
    getConnectionDetailsForActivityChannel,
    getAllTopicsExtended,
    getSubscribedTopics,
    getAllFeeds,
    editFeed,
} from "../../services/activitychannel";
import DraftEditor from "../Editor";
import {
    activityChannelSelector,
    removeDeletedFeed,
    setFeeds,
    setSelectedFeedTopic,
    setSelectedFeedTopicId,
    setModalShowUrgentPost,
    setPageNo,
    setUnreadPostsCount,
} from "../../features/activityChannel/activityChannelSlice";
import { getPersonalizationSettings } from "../../services/personalization";
import toast from "react-hot-toast";
import { store } from "../../app/store";
import { playChatNotification } from "../../utils/AudioVideoDevices";
import { notifyMe } from "../../utils/desktopNotification";
import UrgentPostModal from "../UrgentPostModal";
import { Feed, Role, Topic } from "../../interfaces/slicesInterface/activityChannel";
import { generateRandomQuote } from "../../utils/quotes";
import { capitalizeWords } from "../../utils/datetimeformat";
import { getInitials } from "../../utils/initials";
import { personalizationSelector } from "../../features/personalization/personalization";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { generateThumbnailsUrl } from "../../utils/generateImageURL";

const socketInstanceAC = new SocketService(socketConnectiontype.activityChannel).getInstance(
    socketConnectiontype.activityChannel
);
console.log("hello activity socket", socketInstanceAC);
function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

function useStateRef(initialValue: any) {
    const [value, setValue] = useState(initialValue);

    const ref = useRef(value);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return [value, setValue, ref];
}

export default function ActivityChannel({
    active,
    isACPopupOpened,
    fromACPopup,
    popupWindow
}: ActivityChannelProps): ReturnType<React.FC> {
    const dispatch = useAppDispatch();
    const filters = [
        { id: 1, name: "All Posts", value: "allPosts" },
        { id: 2, name: "My Posts", value: "myPosts" },
        { id: 3, name: "All Topics", value: "topics" },
        { id: 4, name: "My Topics", value: "myTopics" },
    ];

    const [imageError, setImageError] = useState(false);
    const notificationMethods = [
        { id: "normal", title: "Normal" },
        { id: "urgent", title: "Urgent" },
    ];

    const { subscribedTopicIds, selectedFeedTopic, selectedFeedTopicId, modalShowUrgentPost, pageNo, subscribedTopics, loading, allFeeds, allTopics } =
        useAppSelector(activityChannelSelector);
    const personalization = useAppSelector(personalizationSelector);
    const environments = useAppSelector(environmentSelector);

    const spacesConfig = environments.environments && environments.environments.REACT_APP_SPACES_CONFIG ? environments.environments.REACT_APP_SPACES_CONFIG.data[0] : '';
    const [topicSelected, setTopicSelected] = useState<any>();
    const user = useAppSelector(userDetailsSelector);
    console.log({ user });
    // test commit
    // const [pageNo, setPageNo] = useState(1);
    const [postTitle, setPostTitle] = useState("");
    const [showOptions, setShowOptions] = useState(false);
    // const [section, setSection] = useState("allPosts");
    const [open, setOpen] = useState(false);
    const [editorHtml, setEditorHtml] = useState<string>("");
    const [initialData, setInitialData] = useState<string>("");
    const [feedType, setFeedType] = useState("normal");
    const [editPostId, setEditPostId] = useState("");
    const [isEditingPost, setIsEditingPost] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [filteredFeeds, setFilteredFeeds] = useState<Feed[]>(allFeeds);
    const [filteredTopics, setFilteredTopics] = useState<Topic[]>(allTopics);
    // const [selectedFeedTopic, setSelectedFeedTopic, store.getState().activityChannel.selectedFeedTopictateRef("allPosts");
    // const [selectedFeedTopicId, setSelectedFeedTopicId, store.getState().activityChannel.selectedFeedTopicIdtateRef("");
    // const [modalShowUrgentPost, setModalShowUrgentPost] = useState<boolean>(false);
    const [quote, setQuote] = useState<{ quote: string; author: string }>();
    const [titleError, setTitleError] = React.useState<boolean>(false);
    const [wordCountError, setWordCountError] = React.useState<boolean>(false);
    const [descriptionError, setDescriptionError] = React.useState<boolean>(false);
    const [topicError, setTopicError] = React.useState<boolean>(false);
    const [editorError, setEditorError] = useState(false);
    const [activeItem, setActiveItem] = useState<string | null>("allPosts");
    const [showTopics, setShowTopics] = useState(false);
    const showTopicsRef = useRef<HTMLDivElement | null>(null);


    const toggleListBoxOptions = () => {
        setShowTopics(!showTopics)
    }

    const handleToggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const handleModalCloseNewPost = () => {
        dispatch(setModalShowUrgentPost(false));
    };

    const handleActivityViewChange = (topic: any, topicId: any) => {
        // setSection(topic);
        setActiveItem(topic);
        // setSelectedFeedTopic(topic);
        // setSelectedFeedTopicId(topicId);
        dispatch(setSelectedFeedTopic(topic));
        dispatch(setSelectedFeedTopicId(topicId));
        setShowOptions(false);

        if (topic === "allPosts") {
            dispatch(setPageNo(1));
            getFeeds(subscribedTopicIds, 1, false);
            // setSelectedFeedTopic("allPosts");
        } else if (topic === "myPosts") {
            dispatch(setPageNo(1));
            getFeeds(subscribedTopicIds, 1, true);
            // setSelectedFeedTopic("myPosts");
        } else if (topic === "myTopics") {
            // dispatch(getAllTopicsExtended());
        } else if (topic === "topics") {
            // dispatch(getAllTopicsExtended());
        } else {
            dispatch(setPageNo(1));
            getFeeds([topicId], 1, false);
            // setSelectedFeedTopic("");
        }
    };
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        const words = input.split("").filter(word => word.length > 0);

        if (words.length <= 50) {
            setPostTitle(input);
            setWordCountError(false);
        } else {
            setWordCountError(true);
        }

        setTitleError(false);
    };

    console.log({ isACPopupOpened, fromACPopup })



    const getFeeds = async (topicIds: string[], pageNo: Number, fetchMyPostsOnly: boolean) => {
        if (topicIds.length > 0) {
            dispatch(getAllFeeds({ topicIds, pageNo, myPosts: fetchMyPostsOnly }));
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        if (query) {
            if (selectedFeedTopic === "topics") {
                const filtered = allTopics.filter((tpc) => tpc.topic.toLowerCase().includes(query));
                setFilteredTopics(filtered);
            } else {
                const filtered = allFeeds.filter(
                    (feed) =>
                        feed.title.toLowerCase().includes(query) ||
                        feed.description.toLowerCase().includes(query)
                );
                setFilteredFeeds(filtered);
            }
        } else {
            setFilteredFeeds(allFeeds);
            setFilteredTopics(allTopics);
        }
    };

    const handlePost = async () => {
        const finedString = editorHtml
            .replace(/(<([^>]+)>)/gi, "")
            .trim()
            .replace(/&nbsp;/g, " ");
        console.log({ finedString, editorHtml });

        if (postTitle.trim() === "" && finedString === "" && !topicSelected) {
            setTopicError(true);
            setTitleError(true);
            setDescriptionError(true);
            return;
        }

        if (
            postTitle.trim() === "" ||
            finedString === "" ||
            /^ *$/.test(finedString) ||
            !topicSelected
        ) {
            postTitle.trim() === "" && setTitleError(true);
            finedString === "" && setDescriptionError(true);
            /^ *$/.test(finedString) && setDescriptionError(true);

            !topicSelected && setTopicError(true);
            return;
        }

        if (editorHtml && finedString.length > 1000) {
            setEditorError(true);
            return;
        }
        if (isEditingPost) {
            try {
                setOpen(false);
                await editFeed(editPostId, topicSelected?.topicId, postTitle, editorHtml, feedType);
                toast.success("Post Updated");
                setEditPostId("");
                setIsEditingPost(false);
            } catch (e: any) {
                setOpen(false);
                toast.error("Unable to update Post: ", e);
                setEditPostId("");
                setIsEditingPost(false);
            }
        } else {
            socketInstanceAC.sendMessage("NEW_POST_ACTIVITY_CHANNEL", {
                userId: user.userId,
                custId: user.custId,
                title: postTitle,
                description: editorHtml,
                topicId: topicSelected?.topicId,
                firstName: user.firstName,
                lastName: user.lastName,
                type: feedType,
                username: user.username,
            });
            toast.success("Post Created Successfully");
        }

        setOpen(false);
        setPostTitle("");
        setFeedType("normal");
        setTopicSelected(null);
        setInitialData("");
        setEditorHtml("");
        setTopicError(false);
        setTitleError(false);
        setDescriptionError(false);
        // dispatch(setUnreadPostsCount(0));
        // getFeeds(subscribedTopicIds, 1, false);
    };

    const handleEditPost = (
        selectedTopic: string,
        topicId: string,
        postTitle: string,
        postDescription: string,
        postId: string,
        type: string
    ) => {
        setTopicSelected({ topicId, topic: selectedTopic });
        setOpen(true);
        setPostTitle(postTitle);
        // setEditorHtml(postDescription);
        const description = postDescription.replace(/<figure>&nbsp;<\/figure>/g, '')
        console.log({ postDescription }, { description })
        setInitialData(description);
        setEditorHtml(description);
        setEditPostId(postId);
        setFeedType(type);
        setIsEditingPost(true);
    };

    const handleScrollState = async (e: any) => {
        const target = e.target;

        console.log({ scroll: target.scrollHeight - target.scrollTop - target.clientHeight });

        if (loading) return;

        if (Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) < 1) {
            if (selectedFeedTopic === "allPosts") {
                getFeeds(subscribedTopicIds, pageNo + 1, false);
            } else if (selectedFeedTopic === "myPosts") {
                getFeeds(subscribedTopicIds, pageNo + 1, true);
            } else {
                getFeeds([selectedFeedTopicId], pageNo + 1, false);
            }
            dispatch(setPageNo(pageNo + 1));
        }
    };

    const handleEditorChange = (content: string) => {
        setEditorHtml(content);
        setDescriptionError(false);
        setEditorError(false);
    };

    const handleTopicSelected = (content: any) => {
        setTopicSelected(content);
        setTopicError(false);
        setShowTopics(false)
    };

    // useEffect(() => {
    //     if (section === "allPosts") {
    //         setPageNo(1);
    //         getFeeds(subscribedTopicIds, 1, false);
    //         setSelectedFeedTopic("allPosts");
    //     } else if(section === "myPosts") {
    //         setPageNo(1);
    //         getFeeds(subscribedTopicIds, 1, true);
    //         setSelectedFeedTopic("myPosts");
    //     }else{

    //         setPageNo(1);
    //         getFeeds([], 1, false);
    //         // setSelectedFeedTopic("myPosts");

    //     }

    //     // if (section === "topics" ) {
    //     //     dispatch(getAllTopicsExtended());
    //     // }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [section]);

    useEffect(() => {
        if (subscribedTopicIds.length > 0) {
            if (selectedFeedTopic === "allPosts") {
                dispatch(setPageNo(1));
                setActiveItem(selectedFeedTopic);
                getFeeds(subscribedTopicIds, 1, false);
            }

            else if (selectedFeedTopic === "myPosts") {
                dispatch(setPageNo(1));
                setActiveItem(selectedFeedTopic);
                getFeeds(subscribedTopicIds, 1, true);
            }

            else if(selectedFeedTopic=== "topics"){
                dispatch(setPageNo(1));
                setActiveItem(selectedFeedTopic);
            }
            else if(selectedFeedTopic=== "myTopics"){
                setActiveItem(selectedFeedTopic);
            }
            else {
                dispatch(setPageNo(1));
                setActiveItem(selectedFeedTopic);
                getFeeds([selectedFeedTopicId], 1, false);

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribedTopicIds.length]);
    useEffect(() => {
        setActiveItem(selectedFeedTopic);
    }, [selectedFeedTopic])

    useEffect(() => {
        console.log("useeffect", socketInstanceAC);
        dispatch(getAllTopicsExtended());
        dispatch(getSubscribedTopics());

        dispatch(getPersonalizationSettings());
        // // eslint-disable-next-line react-hooks/exhaustive-deps

        // return () => {
        //     console.log("Unsubscribing Activity channel listener & Disconnecting Socket");
        //     socketInstanceAC.closeSocketConnection(true);
        //     socketInstanceAC.reconnectFlushState();
        //     socketInstanceAC.socket = undefined;
        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFilteredFeeds(allFeeds);
    }, [allFeeds]);

    useEffect(() => {
        setFilteredTopics(allTopics);
    }, [allTopics]);

    useEffect(() => {
        setSearchQuery("");
    }, [selectedFeedTopic]);

    useEffect(() => {
        const randomQuote = generateRandomQuote();
        setQuote(randomQuote);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event : MouseEvent) => {
          if (showTopicsRef.current && !showTopicsRef.current.contains(event.target as Node)) {
            setShowOptions(false);
          }
        };
        const targetDocument = isACPopupOpened && popupWindow ? popupWindow.document : document;
    
        targetDocument.addEventListener("mousedown", handleClickOutside);
    
        return () => {
            targetDocument.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showTopicsRef]);

    return (
        <>
            <div
                className={` ${active ? "lg:md:h-[70%]" : "lg:md:h-[82%]"} ${isACPopupOpened && "h-screen scrollbar"
                    }  flex flex-col items-center justify-start `}
            >
                {isACPopupOpened && fromACPopup && (
                    <h1
                        title="Amplify Your Experience with Chats and Feeds"
                        className=" font-sans text-gray-900 text-base font-semibold"
                    >
                        Activity Channel
                    </h1>
                )}

                {isACPopupOpened && !fromACPopup ? (
                    <div className=" h-full w-full flex flex-col justify-start items-center  relative ">
                        <p className=" text-center text-sm w-11/12 font-semibold text-gray-500 shadow-md border-[1px] border-blue p-2 rounded-md  absolute bottom-10 ">
                            Close Newly Opened Feed Tab to Continue Viewing Conversation.
                        </p>

                        {!active && (
                            <div className=" flex flex-col justify-start items-start w-11/12 mt-4">
                                <p className="text-blue leading-relaxed text-sm">
                                    Always Remember:
                                </p>
                                <p className=" text-gray-500 text-sm  ">{quote?.quote}</p>
                                <p className=" text-gray-400 text-sm ml-auto italic">
                                    - {quote?.author}
                                </p>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="flex justify-between items-center px-3 py-1 relative gap-3 w-full mt-1">
                            <div className="relative rounded-md shadow-sm w-[90%]  ">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <MagnifyingGlassIcon
                                        className="h-5 w-5 text-gray-500"
                                        aria-hidden="true"
                                    />
                                </div>
                                <input
                                    type="text"
                                    name="text"
                                    id="text"
                                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-00 text-xs xl:text-xm sm:leading-6 focus:outline-none"
                                    placeholder="Search topics and feeds"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </div>

                            <p
                                className="text-gray-400  text-lg flex justify-center items-center p-1 border-[1px] border-gray-400 hover:text-blue hover:border-blue  transition-colors rounded-md cursor-pointer"
                                title="Start a Post"
                                onClick={() => setOpen(true)}
                            >
                                <PencilSquareIcon className=" h-6 w-auto" />
                            </p>
                            <p
                                className="text-gray-400  text-lg flex justify-center items-center p-1 border-[1px] border-gray-400 hover:text-blue hover:border-blue  transition-colors rounded-md cursor-pointer"
                                title="Filter"
                                onClick={handleToggleOptions}
                            >
                                <Bars3CenterLeftIcon className=" h-6 w-auto" />
                            </p>

                            {showOptions && (
                                <div
                                    ref={showTopicsRef}
                                    className={`${isACPopupOpened && "activityscrollmenu"
                                        } max-h-42  absolute z-10 w-[13rem]  p-2 flex flex-col justify-start items-start shadow-md rounded-xl bg-white right-0 top-[100%] transition-all`}
                                >
                                    {filters.map((filter) => (
                                        <p
                                            key={filter.id}
                                            // className=" font-bold p-2 border border-transparent border-b-gray-400 w-full last-of-type:border-none text-sm hover:bg-gray-50 cursor-pointer"
                                            className={`font-semibold p-2 border border-transparent border-b-gray-400 w-full last-of-type:border-none text-sm hover:bg-gray-50 cursor-pointer ${activeItem === filter.value ? "text-blue" : ""
                                                }`}
                                            onClick={() =>
                                                handleActivityViewChange(filter.value, "")
                                            }
                                        >
                                            {filter.name}
                                        </p>
                                    ))}

                                    {/* {subscribedTopics &&
                                        subscribedTopics.map((tpc: any) =>
                                            tpc.permissions &&
                                                tpc.permissions[user.role as Role].includes("read") ? (
                                                <p
                                                    onClick={() => {
                                                        handleActivityViewChange(
                                                            tpc.topic,
                                                            tpc._id
                                                        );
                                                    }}
                                                    className={`p-2 flex  justify-between align-middle items-center border border-transparent border-b-gray-400 w-full last-of-type:border-none text-xs hover:bg-gray-50 cursor-pointer ${activeItem === tpc.topic ? "text-blue" : ""
                                                        }`}
                                                    // className="p-2 border border-transparent border-b-gray-400 w-full last-of-type:border-none text-sm hover:bg-gray-50 cursor-pointer"
                                                    title={`Subscribed Topic : ${tpc.topic}`}
                                                >
                                                    <p className={` ${isACPopupOpened ? "w-[50%]" : "w-[99%]"} truncate`}>
                                                        {tpc.topic}
                                                    </p>
                                                    <p className="inline-flex items-center   float-end rounded-lg bg-green-100 px-1 py-1 text-[0.6rem] font-medium text-green-500 ring-1 ring-inset ring-green-500/20">
                                                        Subscribed
                                                    </p>
                                                </p>
                                            ) : null
                                        )} */}
                                </div>
                            )}
                        </div>
                        <div
                            className={` flex flex-col justify-start items-center ${styles.height
                                } overflow-hidden p-3 w-full ${isACPopupOpened ? " overflow-y-scroll scrollbar h-screen" : ""
                                }`}
                        >
                            {(selectedFeedTopic === "topics" || selectedFeedTopic === "myTopics") ? (
                                <Topics filteredTopics={filteredTopics} selectedFeedTopic={selectedFeedTopic} handleActivityViewChange={handleActivityViewChange}/>
                            ) : (
                                <Feeds
                                    selectedFeedTopic={selectedFeedTopic}
                                    filteredFeeds={filteredFeeds}
                                    handleEditPost={handleEditPost}
                                    handleScrollState={handleScrollState}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>

            {/* Modal  */}

            <UrgentPostModal show={modalShowUrgentPost} onHide={handleModalCloseNewPost} />
            {/* Modal  */}

            {
                open &&



                <div className="fixed inset-0 z-10 w-screen overflow-y-auto shadow-2xl backdrop-blur-sm transition-all   ">
                    <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[80%] md:max-w-[50%] sm:min-h-[70vh] max-h-[70vh] overflow-y-scroll scrollbar border-2 border-gray-100 sm:p-6">
                            <p
                                className=" absolute right-0 top-2 text-md text-gray-900 cursor-pointer"
                                title="Close"
                                onClick={() => {
                                    setInitialData("");
                                    setOpen(false);
                                    setPostTitle("");
                                    setTopicSelected(null);
                                    setFeedType("normal");
                                    setEditPostId("");
                                    setIsEditingPost(false);
                                    setTopicError(false);
                                    setTitleError(false);
                                    setDescriptionError(false);
                                    setEditorError(false);
                                }}
                            >
                                <XMarkIcon className=" h-5 w-5" />
                            </p>
                            <div className="flex justify-start items-center gap-1   w-min px-2 py-1">

                                {/* <PencilIcon className=" h-5 w-5 text-white" /> */}
                                {personalization.personalization &&
                                    personalization.personalization.imageUrl &&
                                    imageError ? (
                                    <img
                                        className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                        src={
                                            personalization.personalization.imageUrl
                                        }
                                        alt="Hello"
                                    />
                                ) : personalization.personalization &&
                                    personalization.personalization.imageUrl &&
                                    !imageError ? (
                                    <img
                                        className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                        src={generateThumbnailsUrl(
                                            personalization.personalization.imageUrl
                                                .split("/")
                                                .pop(),
                                            240,
                                            user.custId,
                                            user.userId,
                                            spacesConfig
                                        )}
                                        alt="Hello"
                                        onError={() => setImageError(true)}
                                    />
                                ) : (
                                    <span
                                        className="inline-flex lg:h-8 lg:w-8 xl:h-10 xl:w-10 sm:h-10 sm:w-10  items-center justify-center rounded-full bg-blue border-[.5px] border-blue cursor-default"
                                        title={`${user.firstName} ${user.lastName}`}
                                    >
                                        <span className="text-sm font-medium leading-none text-white">
                                            {getInitials(
                                                `${user.firstName} ${user.lastName}`
                                            )}
                                        </span>
                                    </span>
                                )}


                                <div className=" font-medium">
                                    {capitalizeWords(user.username)}
                                </div>
                            </div>

                            <div className=" my-2 flex flex-col justify-start items-start border-b-[.5px] border-gray-300">
                                <p className=" text-xl font-sans ">Publish a post</p>
                                <p className=" text-sm text-gray-500">
                                    Please, select a Topic before filling Title or
                                    Description.
                                </p>
                            </div>

                            <div className=" flex flex-col justify-start items-start w-full gap-3">
                                <div className="sm:block md:lg:xl:flex justify-center items-center gap-2">
                                    <p className="text-sm  text-gray-900 flex justify-start items-start p-0">
                                        {" "}
                                        Post Type?{" "}
                                        <InformationCircleIcon
                                            className="w-5 h-5 text-gray-500 cursor-pointer"
                                            title="Urgent Post will broadcast immediately on all user terminals."
                                        />{" "}
                                    </p>
                                    <fieldset className="mt-1">
                                        <legend className="sr-only">
                                            Notification method
                                        </legend>
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                            {notificationMethods.map(
                                                (notificationMethod) => (
                                                    <div
                                                        key={notificationMethod.id}
                                                        className="flex items-center"
                                                    >
                                                        <input
                                                            id={notificationMethod.id}
                                                            value={notificationMethod.id}
                                                            checked={
                                                                feedType ===
                                                                notificationMethod.id
                                                            }
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                setFeedType(e.target.value);
                                                            }}
                                                            name="notification-method"
                                                            type="radio"
                                                            className="h-4 w-4 border-gray-300 text-blue focus:ring-blue"
                                                        />
                                                        <label
                                                            htmlFor={notificationMethod.id}
                                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                                        >
                                                            {notificationMethod.title}
                                                        </label>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </fieldset>
                                </div>

                                {/* Topics List Box  */}

                                <Listbox
                                    value={topicSelected}
                                    onChange={handleTopicSelected}
                                >
                                    {
                                        <div className="relative w-full">
                                            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                                Topic
                                            </Listbox.Label>
                                            <Listbox.Button
                                                onClick={toggleListBoxOptions}
                                                className={`relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 ${!topicSelected
                                                    ? "placeholder-opacity-50 placeholder-gray-400"
                                                    : ""
                                                    }`}
                                            >
                                                <span
                                                    className={`block truncate ${!topicSelected
                                                        ? "text-gray-400"
                                                        : ""
                                                        }`}
                                                >
                                                    {topicSelected
                                                        ? topicSelected?.topic
                                                        : "Select a topic"}
                                                </span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon
                                                        className="h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Listbox.Button>

                                            {
                                                showTopics &&
                                                <Transition

                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto scrollbar rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {subscribedTopics.map(
                                                            (tpc) =>
                                                                tpc.permissions &&
                                                                tpc.permissions[
                                                                    user.role as Role
                                                                ].includes("write") && (
                                                                    <Listbox.Option
                                                                        key={tpc._id}
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            classNames(
                                                                                active
                                                                                    ? "bg-blue text-white"
                                                                                    : "text-gray-900",
                                                                                "relative cursor-default select-none py-2 pl-3 pr-9"
                                                                            )
                                                                        }
                                                                        value={{
                                                                            topic: tpc.topic,
                                                                            topicId: tpc._id,
                                                                        }}
                                                                    >
                                                                        {({
                                                                            selected,
                                                                            active,
                                                                        }) => (
                                                                            <>
                                                                                <span
                                                                                    className={classNames(
                                                                                        selected
                                                                                            ? "font-semibold"
                                                                                            : "font-normal",
                                                                                        "block truncate"
                                                                                    )}
                                                                                >
                                                                                    {tpc.topic}
                                                                                </span>

                                                                                {selected ? (
                                                                                    <span
                                                                                        className={classNames(
                                                                                            active
                                                                                                ? "text-white"
                                                                                                : "text-blue",
                                                                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                                                                        )}
                                                                                    >
                                                                                        <CheckIcon
                                                                                            className="h-5 w-5"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                )
                                                        )}
                                                    </Listbox.Options>
                                                </Transition>
                                            }

                                            {topicError && (
                                                <p className="lg:mt-[2px] xl:mt-2 text-sm text-red-600">
                                                    *Please Select a Topic
                                                </p>
                                            )}
                                        </div>
                                    }
                                </Listbox>

                                <div className="w-full ">
                                    <label
                                        htmlFor="title"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Title
                                    </label>
                                    <div className="">
                                        <input
                                            type="text"
                                            id="title"
                                            value={postTitle}
                                            onChange={handleTitleChange}
                                            className="block w-full rounded-md border-0 border-gray-400 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6 pl-3"
                                            placeholder="Enter Post Title"
                                        />
                                    </div>
                                    {titleError && (
                                        <p className="lg:mt-[2px] xl:mt-2 text-sm text-red-600">
                                            *Title is Mandatory
                                        </p>
                                    )}
                                    {wordCountError && (
                                        <p className="lg:mt-[2px] xl:mt-2 text-sm text-red-600">
                                            *Title cannot exceed 50 words
                                        </p>
                                    )}
                                    <p className=" text-[.85rem] text-gray-400 mt-1 ">
                                        Characters Count: {postTitle.length}/50
                                    </p>
                                </div>

                                <div className=" w-full ">
                                    <label
                                        htmlFor="description"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Description
                                    </label>

                                    <div
                                        className={`flex items-center space-x-4 border-[1px] border-gray-400 rounded-md ${styles.wrapper_editor}`}
                                    >
                                        <DraftEditor
                                            initialData={initialData}
                                            setEditorContent={handleEditorChange}
                                        />
                                    </div>
                                    {descriptionError && (
                                        <p className="lg:mt-[2px] xl:mt-2 text-sm text-red-600">
                                            *Description is Mandatory
                                        </p>
                                    )}

                                    {editorError && (
                                        <p className="lg:mt-[2px] xl:mt-2 text-sm text-red-600">
                                            *Maximum Character limit for description is 1000
                                        </p>
                                    )}
                                    <p className=" text-[.85rem] text-gray-400 mt-1 ">
                                        Characters Count:{" "}
                                        {
                                            editorHtml
                                                .replace(/(<([^>]+)>)/gi, "")
                                                .trim()
                                                .replace(/&nbsp;/g, " ").length
                                        }
                                        /1000
                                    </p>
                                </div>

                                <div className=" ml-auto">
                                    <Button
                                        type="primary"
                                        onClick={handlePost}
                                        children={
                                            isEditingPost ? "Update Post" : "Create Post"
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            }
        </>
    );
}
