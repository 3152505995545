import { Component, ErrorInfo, ReactNode } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
    errorMessage: ""

  };

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {

      return <div className="flex items-center justify-center h-screen w-full text-xl font-semibold text-black">
     <p><ExclamationCircleIcon className='h-10 w-10 text-red-600 bg-red-50 rounded-full' /></p>&nbsp; Sorry, There is a runtime error : <span className=' text-blue'>{this.state.errorMessage}</span>
     
    </div>
    

    }

    return this.props.children;
  }
}

export default ErrorBoundary;